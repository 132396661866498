<template>
  <div v-loading="is_panding" class="computer-error-form">
      <div class="computer-error-form-wrapper">
        <div class="computer-error-form-wrapper-title">
          <h2>פתיחת תקלה חדשה</h2>
          <div class="return-btn">
            <i @click="$emit('go_back')" class="material-icons">arrow_forward</i>
          </div>
        </div>
        <div class="computer-error-form-wrapper-field">
          <h2>בחירת סניף</h2>
          <el-select style="width:100%;" v-model="values.branche" filterable class="m-2" placeholder="בחירת סניף" size="large">
            <el-option
              v-for="branche in branches_options"
              :key="branche"
              :value="branche"
            />
          </el-select>
        </div>
        <h2>בחירת תקלה:</h2>
        <div class="computer-error-options_btns">
          <template v-for="e in errors_options" :key="e">
            <div :class="{active_btn:e==values.error_type}" @click="values.error_type=e" class="option-btn">{{e}}</div>
          </template>
      
          <!-- <h2>בחר/י מהו המוצר התקול</h2>
          <el-select style="width:100%;" v-model="values.error_type" filterable class="m-2" placeholder="בחירת סוג התקלה" size="large">
            <el-option
              v-for="error in errors_options"
              :key="error"
              :value="error"
            />
          </el-select> -->
        </div>
        <div class="computer-error-form-wrapper-field">
          <h2>תיאור התקלה</h2>
          <el-input
            class="text-area-style"
            v-model="values.description"
            :rows="4"
            type="textarea"
            placeholder="תיאור התקלה..."
          />
        </div>
        <div class="computer-error-form-wrapper-field">
          <h2>שם פותח הקריאה</h2>
          <el-input v-model="values.owner" placeholder="שם פותח הקריאה" />
        </div>
        <div class="computer-error-form-wrapper-field">
          <h2>טלפון נייד של פותח הקריאה</h2>
          <el-input type="tel" v-model="values.phone" placeholder="טלפון נייד" />
        </div>
        <div class="computer-error-form-wrapper-field">
          <h2>העלאת תמונה/ות (לא חובה)</h2>
          <FileUpload 
                  ref="images_preview"
                  @uploader="handle_upload_images_files"
                  uploadLabel="העלאה" chooseLabel="בחירה" cancelLabel="נקה" 
                  :customUpload="true" :multiple="true" 
                  accept="image/*">
                  <template #empty>
                      <p style="text-align:center;">גרירת תמונות לכאן</p>
                      <i @click="handle_choose_images" class="pi pi-upload upload-icon"></i>
                  </template>
          </FileUpload>
        </div>
        <div class="images-grid">
          <template v-for="image in values.images" :key="image.url">
              <div class="box">
                  <div class="box-image">
                      <img :src="image.image_url" alt="">
                  </div>
                  <div class="box-order">
                      <i @click="handle_delete_img(image.image_url)" class="pi pi-trash delete-icon"></i>
                  </div>
              </div>
          </template>
        </div>
        <div class="computer-error-form-wrapper-field">
          <el-button @click="handle_submit" style="width:100%;" type="primary">סיום</el-button>
        </div>
        <!-- JUST FOR MAIL -->
        <div dir="rtl" class="form-email" id="form-email" v-show="false">
            <p style="width:100%; text-align:center; font-size:25px; margin:5px;">
                טופס מערכת תקלות מחשוב סניף {{values.branche}}
            </p>
            <table
                dir="rtl"
                style="width:100%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
            >
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                          שינוי סטטוס קריאה
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        <a :href="mashov_url">לחץ כאן</a>
                    </td>
                </tr>
                <tr style="background-color: #dddddd;">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                          נוצר בתאריך
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{new Date(values.created_at).toLocaleDateString('he')}}
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                          סוג המוצר התקול
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.error_type }}
                    </td>
                </tr>
                <tr style="background-color: #dddddd;">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        תיאור התקלה
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px; white-space: pre-wrap;">
                        {{ values.description }}
                    </td>
                </tr>
                <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        שם פותח הקריאה
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.owner }}
                    </td>
                </tr>
                <tr style="background-color: #dddddd;">
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                          טלפון נייד של פותח הקריאה  
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        {{ values.phone }}
                    </td>
                </tr>
                <template v-for="({image_url},index) in values.images" :key="image_url">
                  <tr>
                    <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
                        תמונה {{ index+1 }}
                    </th>
                    <td style="border:1px solid #dddddd; text-align:right; padding: 8px; text-align:center;">
                        <a :href="image_url" target="_blank">
                          <img style="width:100px; height:100px;" :src="image_url">
                        </a>
                    </td>
                  </tr>
                </template>
            </table>
        </div>
      </div>
  </div>
</template>

<script>
import FileUpload from 'primevue/fileupload'
import { ref } from '@vue/reactivity'
import {  projectFunctions } from '../../../../firebase/config'
import upload_image_storage from '../../../../Methods/use_general_storage'
import {slide_pop_error,alert} from '../../../../Methods/Msgs'
import {store_data_in_db,get_branches_list_names,get_service_person_by_error_type} from '../scripts/scripts'
import {role,user} from '../../../../Methods/Utils'
import {get_errors_from_db} from '../../../../Methods/rosman_shivuk_funcs'

export default {
    components:{FileUpload},
    emits:['submit_new_call','go_back'],
    setup(_,{emit}){
        const err_msg = ref('')
        const {url,uploadImage,delete_image_by_url} = upload_image_storage()
        const branches_options = ref([])
        const errors_options = ref([])
        const images_preview = ref()
        const is_panding = ref(false)
        const mashov_url = ref('')
        const values = ref({
          created_at:new Date(),
          branche:'',
          error_type:'',
          description:'',
          owner:'',
          phone:'',
          images:[],
          month:new Date().getMonth()+1,
          year:new Date().getFullYear(),
          status:'פתוח'
        })
        
        const init = async()=>{
          is_panding.value = true
          let admin_branche = null
          if(role.value >= 99){
            admin_branche = user.value.branche || user.value.name
          }
         
          const branches_list = await get_branches_list_names()
          if(admin_branche) branches_options.value = [admin_branche,...branches_list]
          else branches_options.value = branches_list

          if(admin_branche || user.value.branche){
            values.value.branche = admin_branche || user.value.branche
          }

          const res = await get_errors_from_db()
          errors_options.value = res.map(err => err.name)
          is_panding.value = false

        }

        const handle_choose_images = () => {
            images_preview.value.choose()
        }

        const handle_delete_img = async(url) => {
            await delete_image_by_url(url)
            values.value.images = values.value.images.filter(image=>image.image_url!=url)
        }

        const handle_upload_images_files = async(images_files) => {   
          if(values.value.branche){
            is_panding.value = true
            for(const [index, value] of images_files.files.entries()){
                await uploadImage(value,`ComputerErrors/${values.value.branche}/${new Date()}`)
                values.value.images.push({
                    image_url:url.value,
                })
            }
            is_panding.value = false
            images_preview.value.clear()
          }
          else{
            slide_pop_error('עליך לבחור סניף כדי להעלות תמונות!')
          }
        }

        const validation = () => {
          for (const [key, value] of Object.entries(values.value)) {
            switch (key) {
              case 'branche':
                if(!value){
                  err_msg.value = 'עליך לבחור סניף!'
                  return false
                }
                break;
              case 'error_type':
                if(!value){
                  err_msg.value = 'עליך לבחור מהו המוצר התקול!'
                  return false
                }
                break;
              case 'description':
                if(!value){
                  err_msg.value = 'עליך לרשום את תיאור התקלה!'
                  return false
                }
                break;
              case 'owner':
                if(!value){
                  err_msg.value = 'עליך לרשום את שם פותח הקריאה!'
                  return false
                }
                break;
              case 'phone':
                if(value.length!=10){
                  err_msg.value = 'עליך להזין מספר טלפון נייד תקין!'
                  return false
                }
                break;
            }
          }
          if(images_preview.value.files.length > 0){
            err_msg.value = 'עליך לבצע העלאה של התמונה/ות!'
            return false
          }
          return true
        }

        
        const handle_submit = async() => {
          try{
            const res = await get_service_person_by_error_type(values.value.error_type)
            if(!validation()){
              slide_pop_error(err_msg.value)
            }else{
              is_panding.value = true
              mashov_url.value = await store_data_in_db(values.value)
              setTimeout(async()=>{
                await sendEmail(res?.email)
                is_panding.value = false
                alert('success','הצלחה','הטופס נקלט בהצלחה')
                reset()
                emit('submit_new_call')
              },1000)
            }
          }catch(err){
            console.error(err.message)
            alert('error','אירעה שגיאה',err.message)
          }
        }

        async function sendEmail(email) {
         projectFunctions.httpsCallable('send_email')({
              html: document.getElementById("form-email").innerHTML,
              destinations:["yavrosman@gmail.com",email||''],
              from:"מערכת תקלות מחשוב רוסמן",
              title:`תקלת מחשוב בסניף ${values.value.branche} ב${values.value.error_type}`
          })
        }

        const reset = () => {
          values.value = {
            created_at:new Date(),
            branche:'',
            error_type:'',
            description:'',
            owner:'',
            phone:'',
            images:[],
            status:'פתוח'
          }

        }

        init()

        return{
          handle_choose_images,
          handle_delete_img,
          handle_submit,
          errors_options,
          branches_options,
          values,
          images_preview,
          handle_upload_images_files,
          is_panding,
          err_msg,
          mashov_url
        }
    }
}
</script>

<style scoped>
    .computer-error-form{
      width: 100%;
      height: 100%;
    }
    .computer-error-form-wrapper{
        width: 100%;
        max-width: 500px;
        height: 100%;
        margin: 0 auto;
        overflow-y: auto; 
        padding: 5px 0;
        z-index: 1000;
    }
    .computer-error-form-wrapper-title{
      position: relative;
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .return-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: var(--blue);
        border-radius: 50%;
        cursor: pointer;
    }
    .computer-error-form-wrapper-field{
      width: 100%;
      height: fit-content;
      margin-top: 5px;
    }
    .computer-error-options_btns{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-gap: 5px;
    }
    .computer-error-options_btns .option-btn.active_btn{
      background: var(--success);
    }
    .computer-error-options_btns .option-btn{
      cursor: pointer;
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--purple);
      user-select: none;
      transition: all 0.1s ease-in-out;
      border-radius: 5px;
    }
    .computer-error-options_btns .option-btn:hover{
      transform: scale(1.01);
    }
    :global(.p-button.p-component .p-button-label){
        margin: 0 5px;
    }
    :global(.p-button.p-component.p-button-icon-only .p-button-label){
        margin: unset;
    }
    .upload-icon{
        text-align: center;
        font-size: 25px;
        color: rgb(43, 143, 236);
        width: 100%;
        font-weight: 600;
        margin-top: 5px;
        cursor: pointer;
    }
    .images-grid{
        margin: 5px 0;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-auto-rows: minmax(100px,1fr);
        grid-gap: 5px;
    }
    .box{
        box-shadow: 0 0 2px rgba(0,0,0,0.2);   
        background: #fff;
    }
    .box-order{
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px;
    }
    .box-image{
        width: 100%;
        height: calc(100% - 30px);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .box-image img{
        max-width: 80%;
        max-height: 80%;
    }
    .delete-icon{
        color: var(--danger);
        font-size: 18px;
        cursor: pointer;
    }
    .text-area-style{
      white-space: pre-wrap;
    }
</style>